<template>
  <div ref="page" class="page page_view-ticket page--bottom-indent">
    <component
      v-if="isEdit"
      :is="customService.componentName"
      is-edit
      @close-edit="setEdit"
      :data="ticket.checklistFields"
    />
    <template v-else>
      <div v-if="ticket" class="view-ticket">
        <esmp-link
          v-if="windowSizes.isPhoneLandscape && !windowSizes.isPhonePortrait"
          to=""
          class="view-ticket__info-link"
          @click="showMobileInfoHandler"
        >
          Информация о заявке&nbsp;&rarr;
        </esmp-link>
        <div class="view-ticket__main">
          <div class="view-ticket__header">
            <div class="view-ticket__header-top-line">
              <span
                v-tooltip.click="{value: 'Скопировано', options: {icon: 'copy'}}"
                @click="copyTicketNumber"
              >
                №{{ ticket.number }}
              </span>
              <span class="view-ticket__gray-dot">•</span>
              {{ ticket.createdAt | dateFormat('DD.MM.YYYY, HH:mm') }}
              <span class="view-ticket__gray-dot">•</span>
              <span class="view-ticket__service-name">
                {{ ticket.service }}
              </span>
              <esmp-tag
                v-if="ticketStatusName"
                class="view-ticket__status"
                :color="ticketStatusColor"
                is-status
              >
                {{ ticketStatusName }}
              </esmp-tag>
              <template v-if="ticket.ticketPendingTime && showTicketPendingTime">
                <span class="view-ticket__gray-dot">•</span>
                <span>Отложено до {{ ticket.ticketPendingTime | dateFormat('DD.MM.YYYY, HH:mm') }}</span>
              </template>
            </div>
            <div v-if="ticket.isMassFail" class="view-ticket__massive-fail">
              <esmp-icon name="info2" />
              Массовый сбой
            </div>
            <h2 class="view-ticket__header-title">
              {{ ticket.title }}
            </h2>
            <div class="view-ticket__header-subject" v-html="ticket.subject" />
            <div class="view-ticket__header-description" v-html="ticket.description" />
          </div>

          <div class="view-ticket-additional-info">
            <esmp-collapser
              v-if="ticket.checklistFields?.length || ticket.documents?.length"
              class="view-ticket-collapser"
              title="Дополнительная информация"
            >
              <template v-if="customService && customService.hasCustomChecklist">
                <component
                  :is="customService.customChecklistComponent"
                  :checklist="ticket.checklistFields"
                />
              </template>
              <ticket-fields v-else :fields="ticket.checklistFields" />
              <template v-if="ticket.documents?.length">
                <div class="view-ticket__documents mt-20">
                  <h3 class="mb-20">
                    Документы:
                  </h3>
                  <esmp-collapser
                    v-for="(document, index) in ticket.documents"
                    :key="index"
                    class="view-ticket-collapser"
                    :title="`Документ ${index + 1}`"
                  >
                    <ticket-fields
                      :fields="document.checklistFields"
                    />
                    <attachment-list
                      v-if="document.documentAttachments?.length"
                      class="mt-20"
                      :attachment-list="fileListMap(document.documentAttachments)"
                    />
                  </esmp-collapser>
                </div>
              </template>
            </esmp-collapser>
            <esmp-button
              v-if="windowSizes.isPhonePortrait"
              class="view-ticket-additional-info__link"
              icon="chat-message"
              shape="circular"
              view="ghost"
              @click="scrollToChat"
            />
          </div>

          <esmp-collapser
            v-if="isShowSolution && (ticketStatus.solutionText || ticketStatus.solutionCode)"
            class="view-ticket-collapser view-ticket-collapser--success"
            title="Решение заявки"
          >
            <div class="view-ticket__solution" v-if="ticketStatus.solutionText">
              <h4>Статус</h4>
              <p>{{ ticketStatus.solutionText }}</p>
            </div>

            <div class="view-ticket__solution" v-if="ticketStatus.solutionCode">
              <h4>Дополнительная информация</h4>
              <p>{{ ticketStatus.solutionCode }}</p>
            </div>
          </esmp-collapser>

          <escalation-info
            v-if="ticket.escalationInfo"
            class="view-ticket__escalation"
            :escalation="ticket.escalationInfo"
          />
          <h3
            v-if="showRelatedTitle"
            class="view-ticket__related-title"
          >
            Связанные задачи
          </h3>
          <agreements
            v-if="ticket.approvalData && ticket.approvalData.statuses"
            :agreements="ticket.approvalData.statuses"
            :show-reconciling-button="showReconcilingButton"
            :show-recommender-button="showRecommenderButton"
            class="view-ticket__agreements"
            @show-reconciling-modal="showModals.ModalReconciling = true"
            @show-recommender-modal="showModals.ModalRecommender = true"
            @show-result-modal="showResultModal"
          />
          <sub-task-list
            v-if="ticket.subtaskList && ticket.subtaskList.length"
            class="view-ticket__sub-tasks"
            :task-list="ticket.subtaskList"
          />
          <chat
            ref="chat"
            :messages="commentList"
            :creator="ticket.createBy"
            :customer="ticket.customer"
            :ticket-id="ticket.id"
            :source="sourceSystem"
            :disabled="ticket.commentAction.isHidden"
            :disabled-message="ticket.commentAction.reason"
            @submit="updateCommentsAndAttachments"
          />
        </div>
        <div v-if="!windowSizes.isPhoneLandscape" class="view-ticket__sidebar">
          <info-block
            :ticket="ticket"
            :ticket-priority-color="ticketPriorityColor"
            :ticket-executor="ticketExecutor"
            :is-watcher-showed="isWatcherShowed"
            :show-add-watcher-button="showAddWatcherButton"
            @show-user-info-modal="showUserInfoModal"
            @show-watcher-modal="showModals.ModalWatchers = true"
          />
          <div
            v-if="attachmentList && attachmentList.length"
            class="view-ticket__sidebar-block sidebar-block"
          >
            <h3 class="sidebar-block__title">
              Вложения
            </h3>
            <attachment-list :attachment-list="attachmentList" />
          </div>
        </div>
        <esmp-drawer
          v-if="windowSizes.isPhoneLandscape && !windowSizes.isPhonePortrait"
          class="view-ticket__info"
          title="Информация о заявке"
          :width="360"
          v-model="showMobileInfo"
        >
          <div class="view-ticket__sidebar">
            <info-block
              :ticket="ticket"
              :ticket-priority-color="ticketPriorityColor"
              :ticket-executor="ticketExecutor"
              :is-watcher-showed="isWatcherShowed"
              :show-add-watcher-button="showAddWatcherButton"
              @show-user-info-modal="showUserInfoModal"
              @show-watcher-modal="showModals.ModalWatchers = true"
            />
            <div
              v-if="attachmentList && attachmentList.length"
              class="view-ticket__sidebar-block sidebar-block"
            >
              <h3 class="sidebar-block__title">
                Вложения
              </h3>
              <attachment-list :attachment-list="attachmentList" />
            </div>
          </div>
        </esmp-drawer>
        <modal-ticket-cancel
          :is-show="showModals.ModalTicketCancel"
          :source-system="sourceSystem"
          :ticket-id="ticket.id"
          :type="modalTicketCancelType"
          @hide-modal="hideModal"
          @fetch-tickets="fetchTicketHandler"
          @set-status="setBusinessTripStatus"
        />
        <modal-ticket-reopen
          :is-show="showModals.ModalTicketReopen"
          :source-system="sourceSystem"
          :ticket-id="ticket.id"
          @hide-modal="hideModal"
          @fetch-tickets="getTicketData"
        />
        <modal-ticket-rate
          :is-show="showModals.ModalTicketRate"
          :source-system="sourceSystem"
          :ticket-id="ticket.id"
          @hide-modal="hideModal"
          @fetch-tickets="getTicketData"
        />
        <modal-ticket-approval
          :is-show="showModals.ModalTicketApproval"
          :is-approve="isApprove"
          :tickets="[ticket]"
          @hide-modal="hideModal"
          @fetch-tickets="fetchTicketHandler"
        />
        <modal-ticket-escalation
          v-if="showEscalateButton"
          :is-show="showModals.ModalTicketEscalation"
          :source-system="sourceSystem"
          :ticket-id="ticket.id"
          @on-escalate="getTicketData"
          @hide-modal="hideModal"
        />
        <modal-edit-ticket
          :is-show="showModals.ModalEditTicket"
          @set-edit="setEdit"
          @hide-modal="hideModal"
        />
        <modal-reconciling
          :visible="showModals.ModalReconciling"
          :source-system="sourceSystem"
          :key="`ModalReconciling${showModals.ModalReconciling}`"
          @hide-modal="hideModal"
        />
        <modal-recommender
          :visible="showModals.ModalRecommender"
          :source-system="sourceSystem"
          :key="`ModalRecommender${showModals.ModalRecommender}`"
          @hide-modal="hideModal"
        />
        <modal-watchers
          :visible="showModals.ModalWatchers"
          :watchers="ticket.watcherList"
          :key="`ModalWatchers${showModals.ModalWatchers}`"
          @hide-modal="hideModal"
        />
        <modal-user-info
          v-if="hasCurrentModalUserInfo"
          :is-show="showModals.ModalUserInfo"
          :current-user-info="currentModalUserInfo"
          @hide-modal="hideModal"
        />
        <modal-ticket-stop-watching
          v-if="showStopWatchingButton"
          :is-show="showModals.ModalTicketStopWatching"
          :source-system="sourceSystem"
          :ticket-id="ticket.id"
          @hide-modal="hideModal"
        />
        <modal-approval-result
          :is-show="showModals.ModalApprovalResult"
          :result="approvalResult"
          @hide-modal="hideModal"
        />
      </div>
      <div
        ref="footer"
        v-if="!loading.page && ticket"
        :class="['page__action-buttons', 'page_view-ticket__footer', { 'page__action-buttons--wide': isCollapsedMenu}]"
      >
        <div v-if="windowSizes.isPhonePortrait" class="page_view-ticket__swiper">
          <div ref="gesture" class="page_view-ticket__swiper__gesture-zone">
            <h3>Информация о заявке</h3>
          </div>
          <div class="page_view-ticket__swiper__content">
            <div v-show-slide="showMobileInfo">
              <div class="view-ticket__sidebar">
                <info-block
                  :ticket="ticket"
                  :ticket-priority-color="ticketPriorityColor"
                  :ticket-executor="ticketExecutor"
                  :is-watcher-showed="isWatcherShowed"
                  :show-add-watcher-button="showAddWatcherButton"
                  @show-user-info-modal="showUserInfoModal"
                  @show-watcher-modal="showModals.ModalWatchers = true"
                />
                <div
                  v-if="attachmentList && attachmentList.length"
                  class="view-ticket__sidebar-block sidebar-block"
                >
                  <h3 class="sidebar-block__title">
                    Вложения
                  </h3>
                  <attachment-list :attachment-list="attachmentList" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="page_view-ticket__actions block__actions">
          <esmp-button
            v-if="showBooking"
            :disabled="!enableBook"
            @click="redirectToExternal"
            view="outline"
          >
            Забронировать услуги
          </esmp-button>
          <esmp-button
            v-if="showEdit"
            :disabled="!enableEdit"
            @click="showModals.ModalEditTicket = true"
            view="outline"
          >
            Редактировать заявку
          </esmp-button>
          <esmp-button
            v-if="showReopenButton"
            @click="showModals.ModalTicketReopen = true"
            view="outline"
          >
            Вернуть в работу
          </esmp-button>
          <esmp-button
            v-if="showStopWatchingButton"
            @click="showModals.ModalTicketStopWatching = true"
            view="outline"
          >
            Перестать отслеживать
          </esmp-button>
          <esmp-button
            v-if="showEscalateButton"
            @click="showModals.ModalTicketEscalation = true"
            view="outline"
          >
            Эскалировать
          </esmp-button>
          <esmp-button
            v-if="showCancelButton"
            :disabled="!enableCancel"
            @click="() => {
              isCancelTicket = true;
              showModals.ModalTicketCancel = true;
            }"
            view="outline"
          >
            Отменить заявку
          </esmp-button>

          <esmp-button
            v-if="showResubmitButton"
            @click="resubmit"
            view="outline"
          >
            Повторная подача
          </esmp-button>

          <esmp-button
            v-if="showRateButton"
            icon="star"
            icon-position="left"
            @click="showModals.ModalTicketRate = true"
          >
            Оценить
          </esmp-button>

          <esmp-button
            v-if="showBackButton"
            @click="$router.go(-1)"
          >
            Вернуться назад
          </esmp-button>

          <template v-if="showApprovalButtons">
            <esmp-button
              @click="showModalTicketApproval(true)"
            >
              Согласовать
            </esmp-button>

            <esmp-button
              @click="showModalTicketApproval(false)"
            >
              Отклонить
            </esmp-button>
          </template>

          <esmp-button
            v-if="showCancelApprovalButton"
            view="outline"
            :disabled="!enableCancelApproval"
            @click="() => {
              isCancelTicket = false;
              showModals.ModalTicketCancel = true;
            }"
          >
            Отменить согласование
          </esmp-button>

          <div v-if="ticket && ticket.rating" class="ticket-rating">
            <span>Ваша оценка:</span>
            <esmp-rate :value="Number(ticket.rating)" disabled />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { isEmpty } from 'lodash';
import Hub from '@/plugins/event-hub';
import clearStringFromHTMLTags from '@/utils/clearStringFromHTMLTags';
import { fileListMap } from '@/helpers/attachments';
import { CUSTOM_SERVICES, STATUS_TYPES } from '@/constants/ticket';
import { BUSINESS_TRIP_STATE } from '@/constants/businessTrip';
import BusinessTrip from '@/components/business-trip';
import BusinessTripChecklist from '@/components/business-trip/business-trip-checklist.vue';
import { getStatus } from '@/helpers/status';
import PRIORITY_COLORS_MAP from './helpers/priority.colors';
import TicketFields from './components/ticket-fields';
import InfoBlock from './components/InfoBlock.vue';
import Agreements from './components/Agreements.vue';
import SubTaskList from './components/SubTaskList.vue';
import Chat from './components/Chat.vue';
import EscalationInfo from './components/EscalationInfo.vue';
import AttachmentList from './components/AttachmentList.vue';

const ModalTicketCancel = () => import('@/components/modals/modal-ticket-cancel');
const ModalTicketReopen = () => import('@/components/modals/modal-ticket-reopen');
const ModalTicketRate = () => import('@/components/modals/modal-ticket-rate');
const ModalTicketApproval = () => import('@/components/modals/modal-ticket-approval');
const ModalTicketEscalation = () => import('@/components/modals/modal-ticket-escalation');
const ModalReconciling = () => import('@/components/modals/modal-reconciling');
const ModalWatchers = () => import('@/components/modals/modal-watchers');
const ModalTicketStopWatching = () => import('@/components/modals/modal-ticket-stop-watching');
const ModalUserInfo = () => import('@/components/modals/modal-user-info');
const ModalEditTicket = () => import('@/components/modals/modal-edit-ticket');
const ModalApprovalResult = () => import('@/components/modals/modal-approval-result');
const ModalRecommender = () => import('@/components/modals/modal-recommender');

export default {
  name: 'ViewTicket',
  components: {
    AttachmentList,
    Agreements,
    EscalationInfo,
    TicketFields,
    ModalWatchers,
    ModalEditTicket,
    ModalTicketRate,
    ModalReconciling,
    ModalTicketCancel,
    ModalTicketReopen,
    ModalTicketApproval,
    ModalTicketEscalation,
    ModalTicketStopWatching,
    ModalApprovalResult,
    ModalRecommender,
    BusinessTrip,
    BusinessTripChecklist,
    SubTaskList,
    Chat,
    ModalUserInfo,
    InfoBlock,
  },
  data() {
    return {
      ticket: null,
      attachmentList: [],
      commentList: [],
      showModals: {
        ModalTicketCancel: false,
        ModalTicketReopen: false,
        ModalTicketRate: false,
        ModalTicketApproval: false,
        ModalTicketEscalation: false,
        ModalEditTicket: false,
        ModalReconciling: false,
        ModalWatchers: false,
        ModalUserInfo: false,
        ModalTicketStopWatching: false,
        ModalApprovalResult: false,
        ModalRecommender: false,
      },
      showMobileInfo: false,
      touchOptions: {
        startY: null,
        endY: null,
      },
      isCancelTicket: true,
      isEdit: false,
      currentModalUserInfo: {},
      isApprove: true,
      approvalResult: null,
    };
  },
  computed: {
    ...mapState('system', ['loading', 'config']),
    ...mapState('viewOptions', ['windowSizes']),
    ...mapState('user', ['loggedInUser']),
    ...mapState('sidebar', ['isCollapsedMenu']),
    showRelatedTitle() {
      return this.ticket && (
        (this.ticket?.approvalData?.statuses) || (this.ticket?.subtaskList?.length)
      );
    },
    ticketId() {
      return this.$route.params.id;
    },
    sourceSystem() {
      return this.$route.params.source;
    },
    isApprovalPage() {
      return this.$route.name === 'Approval';
    },
    isOTRS() {
      return this.sourceSystem === 'otrs1';
    },
    ticketStatus() {
      return {
        ...getStatus(this.ticket.state),
        solutionText: clearStringFromHTMLTags(this.ticket.solution),
        solutionCode: clearStringFromHTMLTags(this.ticket.solutionCode),
      };
    },
    ticketStatusName() {
      if (!this.ticketStatus) return undefined;
      return this.ticketStatus.name;
    },
    ticketPriorityColor() {
      if (!this.ticket?.priority) return undefined;
      return this.ticket?.priority?.color || PRIORITY_COLORS_MAP[this.ticket?.priority?.tag];
    },
    ticketStatusColor() {
      if (!this.ticketStatus) return undefined;
      return this.ticketStatus.color;
    },
    ticketExecutor() {
      return this.ticket?.responsible;
    },
    isWatcherShowed() {
      return this.config?.ticketWatchersAvailable;
    },
    showAddWatcherButton() {
      return this.isWatcherShowed && this.ticket?.canSetWatchers;
    },
    showReopenButton() {
      return this.ticket?.showReopenButton;
    },
    showCancelButton() {
      return this.ticket?.showCancelButton && !this.isApprovalPage;
    },
    showResubmitButton() {
      return this.ticket?.showCreateChecklistWithValuesFromCurrentTicket;
    },
    showStopWatchingButton() {
      return this.isWatcherShowed && this.ticket?.canStopWatching;
    },
    showEscalateButton() {
      return this.ticket?.canEscalate;
    },
    showApprovalButtons() {
      return this.ticket?.showApprovalButtons && this.isApprovalPage;
    },
    showReconcilingButton() {
      return this.showApprovalButtons && this.isOTRS && this.ticket?.showAddExtraApproverButton;
    },
    showRecommenderButton() {
      return this.showApprovalButtons && this.isOTRS && this.ticket?.showAddRecommenderButton;
    },
    // TODO: переместить на бек (ESMPCP-1839)
    showRateButton() {
      // eslint-disable-next-line max-len
      return [STATUS_TYPES.resolved.id, STATUS_TYPES.executed.id].includes(this.ticketStatus.code) && !this.ticket?.rating;
    },
    // TODO: переместить на бек (ESMPCP-1839)
    showBackButton() {
      return [STATUS_TYPES.rejected.id, STATUS_TYPES.removed.id].includes(this.ticketStatus.code);
    },
    showCancelApprovalButton() {
      if (!this.customService?.isAvailableToCancelApproval) {
        return false;
      }

      return this.ticket?.businessTripData?.state !== BUSINESS_TRIP_STATE.isHidden;
    },
    showEdit() {
      if (!this.customService?.isEditable) return false;

      return this.ticket?.businessTripData?.state !== BUSINESS_TRIP_STATE.isHidden;
    },
    showBooking() {
      if (!this.customService?.isAvailableToBook) {
        return false;
      }

      // TMP: вернуть, как бэк будет готов
      // if (!this.ticket.isOnlineBooking) {
      //   return false;
      // }

      // TMP: для конкретного исполителя
      if (this.ticket?.responsible?.email !== 'online_kom@rt.ru') {
        return false;
      }

      const isVIPGrade = !!this.ticket?.checklistFields?.find((f) => f.id === 'isVIPGrade');
      const isVip = isVIPGrade?.value === 'true';

      return !isVip && this.ticket?.businessTripData?.state !== BUSINESS_TRIP_STATE.isHidden;
    },
    // TODO: переместить на бек (ESMPCP-1839)
    enableCancel() {
      if (this.ticket?.businessTripState === BUSINESS_TRIP_STATE.approval) {
        return false;
      }

      let value = true;

      // eslint-disable-next-line default-case
      switch (this.ticketStatus.code) {
      case STATUS_TYPES.removed.id:
      case STATUS_TYPES.closed.id:
      case STATUS_TYPES.closedSuccessful.id:
      case STATUS_TYPES.closedUnsuccessful.id:
        value = false;
        break;
      }

      return value;
    },
    enableCancelApproval() {
      return this.ticket?.businessTripData?.state === BUSINESS_TRIP_STATE.approval;
    },
    enableEdit() {
      return (
        this.ticket?.businessTripData?.state === BUSINESS_TRIP_STATE.approval
        || this.ticket?.businessTripData?.state === BUSINESS_TRIP_STATE.editable);
    },
    // TODO: переместить на бек (ESMPCP-1839)
    enableBook() {
      return this.ticketStatus.code === STATUS_TYPES.open.id;
    },
    customService() {
      const customService = Object.values(CUSTOM_SERVICES).find(
        (service) => service.serviceName === this.ticket?.service,
      );

      return customService || null;
    },
    modalTicketCancelType() {
      // if (this.showApprovalButtons) {
      //   return 'approval';
      // }
      return this.isCancelTicket ? 'ticket' : 'approval-request';
    },
    hasCurrentModalUserInfo() {
      return !isEmpty(this.currentModalUserInfo);
    },
    // TODO: переместить на бек (ESMPCP-1839)
    showTicketPendingTime() {
      return ![
        STATUS_TYPES.closedSuccessful.id,
        STATUS_TYPES.closedUnsuccessful.id,
        STATUS_TYPES.cancel.id,
        STATUS_TYPES.closed.id,
        STATUS_TYPES.rejected.id,
        STATUS_TYPES.removed.id,
        STATUS_TYPES.resolved.id,
        STATUS_TYPES.executed.id,
        STATUS_TYPES.closedLower.id].includes(this.ticketStatus.code);
    },
    isShowSolution() {
      return [
        STATUS_TYPES.closedUnsuccessful.id,
        STATUS_TYPES.closedSuccessful.id,
        STATUS_TYPES.executed.id,
        STATUS_TYPES.rejected.id,
        STATUS_TYPES.removed.id,
        STATUS_TYPES.cancel.id,
        STATUS_TYPES.closed.id,
        STATUS_TYPES.closedLower.id,
        STATUS_TYPES.resolved.id].includes(this.ticketStatus.code);
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    ...mapActions('ticket', ['fetchUserAdditionalInfo']),
    fileListMap,
    showModalTicketApproval(isApprove) {
      this.isApprove = isApprove;
      this.showModals.ModalTicketApproval = true;
    },
    showResultModal(resultData) {
      this.approvalResult = resultData;
      this.showModals.ModalApprovalResult = true;
    },
    updateCommentsAndAttachments(data) {
      this.attachmentList = fileListMap(data.attachmentList);
      this.commentList = data.commentList;
    },
    copyTicketNumber() {
      const textToCopy = this.ticket?.number;
      this.$copyToClipboard(textToCopy).then(() => {
        this.$EsmpNotify.$show(`Номер тикета ${textToCopy} скопирован в буфер обмена`, 'success');
      }).catch(() => {
        this.$EsmpNotify.$show(`Номер тикета ${textToCopy} не удалось скопировать в буфер обмена`, 'success');
      });
    },
    hideModal(payload) {
      if (this.showModals[payload.modalName]) {
        this.showModals[payload.modalName] = payload.newValue;
      }
      if (payload.modalName === 'ModalUserInfo') this.clearCurrentModalUserInfo();
    },
    getTicketData() {
      this.setLoading({ key: 'page', value: true });
      const getTicketOrApproval = this.isApprovalPage
        ? this.$API.approval.getApproval
        : this.$API.ticket.getTicket;
      getTicketOrApproval(this.ticketId, {
        source: this.sourceSystem,
      }).then(({ data }) => {
        this.ticket = data;
        this.$router.setMetaOption(this.$route, 'title', this.ticket.number);
        this.attachmentList = fileListMap(data.attachmentList);
        this.commentList = data.commentList;
      }).finally(() => {
        this.setLoading({ key: 'page', value: false });
        setTimeout(this.addEvents, 1100);
      });
    },
    fetchTicketHandler() {
      if (this.isApprovalPage) {
        this.$router.push({
          name: 'Approvals',
        });
      } else {
        this.getTicketData();
      }
    },
    setBusinessTripStatus(status) {
      this.ticket.businessTripData.state = status;
    },
    setEdit(state) {
      this.$API.businessTrip.startEditing(
        this.ticketId,
        {
          comment: 'Редактирование заявки на командировку клиентом',
          source: this.sourceSystem,
        },
      );

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

      this.isEdit = state;
    },
    async showUserInfoModal(ticketCustomer) {
      if (this.config?.userInfoAvailable) {
        this.currentModalUserInfo = ticketCustomer.additionalInfo
          ? ticketCustomer.additionalInfo
          : await this.fetchUserAdditionalInfo(ticketCustomer.email);
        if (!this.currentModalUserInfo.code) this.showModals.ModalUserInfo = true;
      }
    },
    clearCurrentModalUserInfo() {
      this.currentModalUserInfo = {};
    },
    showMobileInfoHandler() {
      this.showMobileInfo = !this.showMobileInfo;
    },
    scrollToChat() {
      this.$refs.chat.$refs.message.scrollIntoView({ block: 'center', behavior: 'smooth' });
    },
    gestureHandler() {
      if (this.touchOptions.endY <= this.touchOptions.startY) {
        this.showMobileInfo = true;
      } else {
        this.showMobileInfo = false;
      }
    },
    touchstartHander(event) {
      this.touchOptions.startY = event.changedTouches[0].screenY;
    },
    touchendHander(event) {
      this.touchOptions.endY = event.changedTouches[0].screenY;
      this.gestureHandler();
    },
    addEvents() {
      if (this.$refs.gesture) {
        this.$refs.gesture.addEventListener('touchstart', this.touchstartHander, false);
        this.$refs.gesture.addEventListener('touchend', this.touchendHander, false);

        this.$refs.page.style.paddingBottom = `${this.$refs.footer.offsetHeight}px`;
      }
    },
    resubmit() {
      this.$router.push({
        name: 'CreateTicket',
        params: { source: this.sourceSystem, serviceId: this.ticket.serviceId },
        query: { ticketId: this.ticketId, customerLogin: this.ticket.customer.email, isResubmit: true },
      });
    },
    async redirectToExternal() {
      this.setLoading({ key: 'page', value: true });

      await this.$API.businessTrip.getBookingRef({
        ticketId: this.ticket.id,
        email: this.loggedInUser.login,
      })
        .then((response) => {
          window.open(response.data, '_blank').focus();
        })
        .catch((error) => error)
        .finally(() => {
          this.setLoading({ key: 'page', value: false });
        });
    },
  },
  created() {
    this.getTicketData();
    Hub.$on('update-ticket', this.getTicketData);
    Hub.$on('show-user-info-modal', this.showUserInfoModal);
  },
  beforeDestroy() {
    Hub.$off('update-ticket');
    Hub.$off('show-user-info-modal');

    if (this.$refs.gesture) {
      this.$refs.gesture.removeEventListener('touchstart', this.touchstartHander);
      this.$refs.gesture.removeEventListener('touchend', this.touchendHander);
    }
  },
};
</script>

<style lang="scss">
.page {
  &_view-ticket {
    @include for-size(phone-portrait-down) {
      margin-left: -20px;
      margin-right: -20px;
    }

    &__footer {
      @include for-size(phone-portrait-down) {
        position: fixed;
        left: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-height: calc(100vh - #{$header-height});
        margin: 0;
        padding: 16px 20px;
        border-radius: $base-border-radius * 2 $base-border-radius * 2 0 0;
        box-shadow: 0px 8px 20px rgba(28, 41, 61, 0.2);
        z-index: 2;
      }
    }

    &__swiper {
      background: $color-white;
      display: flex;
      flex-direction: column;

      max-height: 50vh;
      flex-grow: 1;
      &__gesture-zone {
        position: relative;
        flex-grow: 0;
        min-height: 78px;
        padding-top: 32px;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: calc(50% - 20px);
          width: 40px;
          height: 4px;
          background-color: $color-black-op-25;
          border-radius: 4px;
        }
      }
      &__content {
        flex-grow: 1;
        overflow-y: auto;
      }
    }

    &__actions {
      display: flex;
      align-items: flex-start;
      flex-grow: 0;

      @include for-size(phone-landscape-down) {
        flex-wrap: wrap;
      }

      @include for-size(phone-portrait-down) {
        position: relative;
        padding-top: 12px;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: -20px;
          width: 100vw;
          border-top: 1px solid $color-gray-status-op-15;
        }
      }

      .esmp-button-wrapper {
        margin: 0 16px 16px 0;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
.additional-info-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.additional-info-icon {
  margin: 0 5px 0 0;
}
.view-ticket-additional-info {
  position: relative;
  &__link {
    min-height: 48px;
    position: absolute;
    top: -32px;
    right: 0;
    background-color: $color-white;
  }
}
.view-ticket {
  width: 100%;
  margin: 0 auto;
  display: flex;
  @include for-size(phone-landscape-down) {
    display: block;
  }

  &__info-link {
    margin-bottom: 28px;
    display: block;
  }

  &__main {
    flex-grow: 1;
    background: $color-white;
    border-radius: $base-border-radius * 2;
    padding: 32px;
    width: 100%;

    .view-ticket-collapser {
      margin-bottom: 20px;
      @include for-size(phone-portrait-down) {
        padding: 10px;
        .esmp-collapser__title {
          font-size: 13px;
        }
        .esmp-collapser__body {
          padding: 0 8px 8px 8px;
        }
      }
    }

    @include for-size(phone-portrait-down) {
      padding: 20px 20px 0;
      border-radius: $base-border-radius * 2 $base-border-radius * 2 0 0;
    }
  }
  &__sidebar {
    width: $right-column-width;
    flex-shrink: 0;
    margin-left: $gap;
    &-block {
      margin: 18px 0 0 0;
      padding: 20px 16px;
      background: #fff;
      border-radius: 16px;
      &:first-child {
        margin: 0;
      }
    }
    @include for-size(phone-landscape-down) {
      width: 100%;
      margin-left: 0;
    }
  }
  &__header {
    margin-bottom: 20px;

    &-title {
      margin: 8px 0 0 0;
    }
    &-subject {
      margin: 12px 0 0 0;
      font-size: 16px;
      line-height: 24px;
    }
    &-description {
      margin: 8px 0 0 0;
      font-size: 14px;
      line-height: 20px;
      color: rgba(16, 24, 40, 0.7);
      @include for-size(phone-portrait-down) {
        font-size: 13px;
      }
    }
    &-top-line {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      font-size: 14px;
      line-height: 20px;
      color: #B5B7C0;
      @include for-size(phone-portrait-down) {
        font-size: 13px;
      }
    }
  }
  &__gray-dot {
    display: inline-block;
    margin: 0 3px;
    font-size: 18px;
    line-height: 20px;
  }
  &__status {
    margin-left: 3px;
  }
  &__massive-fail {
    display: inline-flex;
    align-items: center;
    color: $color-error-night;
    font-size: 12px;
    line-height: 16px;
    margin-left: -6px;
    svg {
      width: 32px;
      height: 32px;
    }
  }
  &__add-watchers {
    cursor: pointer;
    font-size: 13px;
    line-height: 20px;
    margin: 14px 0 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #9466ff;
    &:hover {
      color: #70f;
    }
  }
  &__escalation {
    margin: 16px 0 0 0;
  }
  &__related-title {
    margin: 32px 0 0 0;
  }

  &__solution {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  &__info {
    .esmp-drawer-header {
      border-bottom: none;
      padding-bottom: 0;
    }
    .esmp-drawer-content {
      background-color: $color-grayscale-05;
    }
  }
}
.sidebar-block {
  &__title {
    margin: 0 0 20px 0;
  }
}
.ticket-info {
  &-row {
    margin: 12px 0 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    font-size: 12px;
    line-height: 16px;
    &:first-child {
      margin: 0;
    }

    &--align-top {
      align-items: flex-start;
    }
  }
  &-label {
    width: 95px;
    color: rgba(121, 126, 139, 1);
    flex-shrink: 0;
  }
  &-value {
    display: flex;
    flex-direction: row;
    align-items: center;

    &--column {
      flex-direction: column;
    }
  }
  &-avatar {
    margin: -2px 5px 0 0;
    flex-shrink: 0;
  }

  &__link {
    @include hover(true) {
      color: $color-client-portal-logo;
    }
  }
}
.ticket-watcher {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 0 0;
  &:first-child {
    margin: 0;
  }
}
.ticket-priority {
  display: inline-block;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  padding: 4px 8px 2px;
  border-radius: 8px;
}
.esmp-modal-footer .esmp-button-wrapper {
  @include for-size(phone-portrait-down) {
    margin: 6px 16px 6px 0;
  }
}
</style>

<template>
  <div
    v-if="agreements"
    class="agreements"
  >
    <agreements-item
      v-for="(block, key) in agreementsMap"
      class="agreements__item"
      :key="key"
      :index="key"
      :title="block.title"
      :start-date-time="block.startDateTime"
      :type="block.type"
      :status="block.status"
      :members="block.members"
      :show-reconciling-button="showReconcilingButton"
      :show-result-button="!!block.resultData.length"
      :show-recommender-button="showRecommenderButton"
      @show-reconciling-modal="$emit('show-reconciling-modal')"
      @show-result-modal="$emit('show-result-modal', block.resultData)"
      @show-recommender-modal="$emit('show-recommender-modal')"
    />
  </div>
</template>

<script>
import kebabCase from 'lodash/kebabCase';
import { AGREEMENT_TYPES_MAP, APPROVE_STATUSES_MAP } from '@/constants/ticket';
import { nameWithInitials } from '@/utils/userInitials';

const sortedStatuses = APPROVE_STATUSES_MAP.sortByWeight('desc');

const getStatus = (arr, type) => {
  const approved = {
    color: APPROVE_STATUSES_MAP.Approved.color,
    text: APPROVE_STATUSES_MAP.Approved.translate,
  };
  const pending = {
    color: APPROVE_STATUSES_MAP.Pending.color,
    text: APPROVE_STATUSES_MAP.Pending.translate,
  };
  const denied = {
    color: APPROVE_STATUSES_MAP.Denied.color,
    text: APPROVE_STATUSES_MAP.Denied.translate,
  };
  // По весу
  if (['any', 'unknown'].includes(type)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const status of sortedStatuses) {
      if (arr.find((task) => task.status === status.code)) {
        return {
          color: APPROVE_STATUSES_MAP[status.code].color,
          text: APPROVE_STATUSES_MAP[status.code].translate,
        };
      }
    }
    return pending;
  }
  if (type === 'all') {
    if (arr.every((el) => el.status === 'Approved')) {
      return approved;
    }
    if (arr.some((el) => el.status === 'Denied')) {
      return denied;
    }
    return pending;
  }
  if (type === 'most') {
    // TODO проверить логику, по-моему она некоретная, а вообще все это на бек надо вынести
    // например, имеем statusCounter {a: 3, b: 2, c: 2, d: 1}
    // наибольший вес у a = 3, но это меньше Math.floor(8 / 2);
    // надо перебирать значения счетчика и определять наибольшее значение
    const mostMinValue = Math.floor(arr.length / 2);
    const statusCounters = {};
    arr.forEach((el) => {
      if (!statusCounters[el.status]) {
        statusCounters[el.status] = 0;
      }
      statusCounters[el.status] += 1;
    });

    // eslint-disable-next-line no-restricted-syntax
    for (const status of sortedStatuses) {
      if (
        statusCounters[status.code]
        && statusCounters[status.code] > mostMinValue
      ) {
        return this.getResult(status.code);
      }
    }

    console.warn(
      'setStatusDataByPolicy: не найдено преобладающих статусов для согласований! '
      + 'Проверьте значения',
    );
  }
  return {
    color: 'gray',
    text: 'Неизвестно',
  };
};
const AgreementsItem = () => import('./AgreementsItem.vue');

export default {
  name: 'Agreements',
  components: { AgreementsItem },
  props: {
    agreements: {
      type: Array,
      default: undefined,
    },
    showReconcilingButton: {
      type: Boolean,
    },
    showRecommenderButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    agreementsMap() {
      const result = {};
      if (!this.agreements) return result;
      const sorted = [...this.agreements].sort((a, b) => a.position - b.position);
      sorted.forEach((el) => {
        if (!result[el.position]) {
          result[el.position] = {
            startDateTime: el.startDate,
            type: AGREEMENT_TYPES_MAP[el.policy],
            status: {},
            members: [],
            title: el.type,
            id: el.approvalId,
            resultData: [],
          };
        }
        result[el.position].members.push({
          fullName: el.approverFullName,
          email: el.approverEmail,
          status: el.status,
          statusKebabed: kebabCase(el.status),
          caption: APPROVE_STATUSES_MAP[el.status].translate,
        });
        const status = getStatus(result[el.position].members, el.policy);
        if (el.checklistFields) {
          result[el.position].resultData.push({
            approvalName: nameWithInitials(el.approverFullName),
            checklistFields: el.checklistFields,
            status: APPROVE_STATUSES_MAP[el.status],
          });
        }
        result[el.position].status = status;
      });
      return result;
    },
  },
};
</script>

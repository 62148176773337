<template>
  <div class="escalation">
    <h3 class="escalation__title">
      Эскалация
    </h3>
    <div class="escalation__fields">
      <div class="escalation__field">
        <div class="escalation__field-label">
          Причина
        </div>
        <div class="escalation__field-value">
          {{ escalation.reasonName }}
        </div>
      </div>
      <div class="escalation__field">
        <div class="escalation__field-label">
          Запрашиваемая дата
        </div>
        <div class="escalation__field-value">
          {{ new Date(escalation.decisionDate) | dateFormat }}
        </div>
      </div>
      <div class="escalation__field">
        <div class="escalation__field-label">
          Комментарий
        </div>
        <div class="escalation__field-value">
          {{ escalation.creatorMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EscalationInfo',
  props: {
    escalation: {
      type: Object,
      default: undefined,
    },
  },
};
</script>

<style lang="scss" scoped>
.escalation {
  padding: 24px;
  border-radius: 12px;
  border: 1px rgba(232, 232, 238, 1) solid;
  background: #fff;
  &__field {
    margin: 20px 0 0 0;
    &-label {
      font-weight: bold;
      font-size: 14px;
    }
    &-value {
      margin: 4px 0 0 0;
      font-size: 14px;
    }
  }
}
</style>
